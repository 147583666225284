'use client'

import { type ImageFieldsFragment } from '@lib/__generated/sdk'
import Image, { type ImageLoaderProps, type ImageProps } from 'next/image'

const contentfulLoader = ({ src, width, quality }: ImageLoaderProps) =>
  `${src}?w=${width}&q=${quality ?? 75}`

interface ContentfulImageProps
  extends Omit<ImageProps, 'title' | 'alt' | 'src' | 'width' | 'height'>,
    ImageFieldsFragment {
  forceWidth?: boolean
}

const ContentfulImage = ({
  url,
  sys,
  title,
  width,
  height,
  contentType,
  forceWidth,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  __typename,
  ...properties
}: ContentfulImageProps) => {
  if (forceWidth) {
    return (
      <Image
        src={`${url}?w=${width}&q=${properties.quality ?? 75}`}
        alt={title ?? ''}
        height={height as number}
        width={width as number}
        {...properties}
        unoptimized
      />
    )
  }
  return (
    <Image
      src={url as string}
      alt={title ?? ''}
      height={height as number}
      width={width as number}
      {...properties}
      loader={contentfulLoader}
    />
  )
}

export default ContentfulImage
